

.artemis-logo {
  margin: auto;
}

@include media-breakpoint-down(lg) {
  .artemis-logo {
    width: 40px;
    margin-top: 3rem;
  }
}

@include media-breakpoint-up(lg) {
  .artemis-logo {
    width: 200px;
  }
}

body.vertical-collpsed {
  .artemis-logo {
    width: 40px;
  }
}



.bot-table {
  tr:hover {
    cursor: pointer;
    background-color: #f5f7f9;
  }
}

.coin-balance {
  cursor: default !important;
}

.hover-cursor:hover {
  cursor: pointer;
}
